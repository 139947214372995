<template>
<div class="erp-console-container">
  <div class="erp-c-input">
    <erp-s-field
        view="tl"
        label="Comando:"
        label-width="90px"
    >
      <u-input autofocus v-model="command" inverted color="white" class="text-black" @keydown.enter="exec" />
      <u-btn :loading="loading" class="btn-exec bg-blue text-white" label="Executar" size="sm" @click="exec" />
    </erp-s-field>
  </div>
  <div class="erp-console-log erp-terminal" ref="terminal">
    <vue-typed-js :typeSpeed="5" class="hideCursor"
                  :strings="['Execute um comando para visualizar os logs...']">
      <div class="typing text-positive"></div>
    </vue-typed-js>
    <vue-typed-js :typeSpeed="5" :startDelay="1000" class="hideCursor"
                  :strings="['Dúvidas consulte a documentação de comandos disponíveis']">
      <div class="typing text-positive"></div>
    </vue-typed-js>
    <vue-typed-js :typeSpeed="5" :startDelay="2000" class="hideCursor"
                  :strings="['Use com extremo cuidado!']">
      <div class="typing font-bold text-warning"></div>
    </vue-typed-js>
    <div v-for="message in buffer" :key="message.id" class="log log-buffer m-t-xs">
      [{{ message.date.date|formatDate }}] [{{message.person}}] [{{message.user}}] <span v-html="parseMessage(message.message)"></span>
    </div>
  </div>
</div>
</template>

<script>
import {UInput} from "uloc-vue"
import {ErpSField} from "@/plugins/uloc-erp"
import {logBuffer} from "@/domain/logs/service/log"
import {execCommand} from "@/domain/gerencia/servidor/services"
import {subHours, addHours, format} from "date-fns"

export default {
  data () {
    return {
      loading: false,
      command: '',
      buffer: []
    }
  },
  mounted() {
    this.$logBufferInterval = setInterval(this.loadLogs, 4000)
  },
  beforeDestroy() {
    this.$logBufferInterval && clearInterval(this.$logBufferInterval)
  },
  computed: {
    entity () {
      if (this.command.startsWith('deploy')) {
        return 'source'
      }
      return 'user'
    },
    entityId () {
      if (this.command.startsWith('deploy')) {
        return ''
      }
      return this.$uloc.auth.session.user.id
    }
  },
  methods: {
    loadLogs() {
      let command = this.command
      // command = command.split(' ')
      // command = command[0]
      let date1 = format(subHours(new Date(), 1), 'yyyy-MM-dd HH:mm:ss')
      let date2 = format(addHours(new Date(), 1), 'yyyy-MM-dd HH:mm:ss')
      logBuffer('com:' + command, this.entity, this.entityId, date1, date2)
          .then(({data}) => {
            this.buffer = data
          })
          .catch(error => {
            console.log(error)
          })
    },
    exec () {
      this.loading = true
      execCommand(this.command)
          .then(response => {
            this.loading = false
            console.log(response.data)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    parseMessage (m) {
      return m.replace(/\n/g, "<br>")
    }
  },
  components: {
    ErpSField,
    UInput
  }
}
</script>
